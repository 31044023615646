import React from 'react';
import { Svg } from '../svg';
import { Box, BoxProps } from '../box';

export const CloseIcon: React.FC<BoxProps> = props => (
  <Box {...props}>
    <Svg width="100%" viewBox="0 0 10 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81707 0.403005C1.62846 0.220847 1.37586 0.120052 1.11366 0.122331C0.851468 0.124609 0.600656 0.229778 0.415247 0.415186C0.229839 0.600595 0.12467 0.851407 0.122392 1.1136C0.120113 1.3758 0.220908 1.6284 0.403066 1.81701L3.58607 5L0.403066 8.183C0.215425 8.37051 0.109957 8.62488 0.109863 8.89015C0.109817 9.0215 0.135642 9.15157 0.185864 9.27294C0.236086 9.39431 0.309721 9.50459 0.402566 9.5975C0.495411 9.69041 0.605646 9.76413 0.726978 9.81444C0.848311 9.86475 0.978364 9.89066 1.10971 9.89071C1.37498 9.8908 1.62943 9.78551 1.81707 9.598L5.00007 6.415L8.18307 9.598C8.37071 9.78564 8.6252 9.89106 8.89057 9.89106C9.15593 9.89106 9.41043 9.78564 9.59807 9.598C9.78571 9.41036 9.89112 9.15587 9.89112 8.8905C9.89112 8.62514 9.78571 8.37065 9.59807 8.183L6.41507 5L9.59807 1.81701C9.69091 1.7241 9.76454 1.61381 9.81477 1.49244C9.86499 1.37107 9.89082 1.241 9.89077 1.10965C9.89072 0.978303 9.86481 0.84825 9.8145 0.726917C9.76419 0.605585 9.69048 0.49535 9.59757 0.402505C9.50466 0.30966 9.39437 0.236025 9.273 0.185803C9.15163 0.135581 9.02156 0.109756 8.89021 0.109802C8.75886 0.109849 8.62881 0.135766 8.50748 0.186073C8.38615 0.236381 8.27591 0.310094 8.18307 0.403005L5.00007 3.58601L1.81707 0.403005Z"
        fill="currentColor"
      />
    </Svg>
  </Box>
);
