import React from 'react';
import { Box, BoxProps } from '../box';

export const CheckmarkCircleIcon: React.FC<BoxProps> = props => (
  <Box {...props}>
    <svg width="100%" viewBox="0 0 16 17" fill="none">
      <path
        d="M7.99613 16.9961C12.3809 16.9961 16 13.377 16 8.99996C16 4.61523 12.3731 0.996094 7.9884 0.996094C3.61141 0.996094 0 4.61523 0 8.99996C0 13.377 3.61914 16.9961 7.99613 16.9961ZM6.94442 13.0367C6.65056 13.0367 6.41856 12.9052 6.1943 12.6423L3.96713 9.92794C3.82794 9.75781 3.75834 9.56448 3.75834 9.37889C3.75834 8.96129 4.08313 8.64423 4.47753 8.64423C4.71725 8.64423 4.91832 8.74476 5.11165 8.97676L6.92122 11.2581L10.4089 5.69015C10.579 5.41175 10.8033 5.28029 11.043 5.28029C11.4297 5.28029 11.8009 5.55095 11.8009 5.96081C11.8009 6.14641 11.7081 6.33974 11.5998 6.50987L7.67134 12.6191C7.48574 12.8975 7.23828 13.0367 6.94442 13.0367Z"
        fill="currentColor"
      />
    </svg>
  </Box>
);
